<template>
  <section class="hero-lg">
    <div class="container">
      <h1 class="text-center">Help</h1>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-6">
          <h2>Story</h2>
          <p>
            Fibn started on a sleepless night based on many years of planning
            projects and organizing teams. The game is based on the Fibonacci
            sequence to plan and complete sprints while competing with friends.
            The game is quick-wittedly fun as you deal, steal, and outmaneuver
            other players.
          </p>
          <p>
            While Fibn is inspired by agile practices, it can be enjoyed by
            anyone who likes card games like Phase 10, Rack-O, and others. The
            game focuses on precision and collaboration, helping players
            understand that fast does not translate to complete.
          </p>
        </div> -->
        <div class="col">
          <img
            src="../../assets/help/help.png.webp"
            class="img-fluid d-block mx-auto"
            alt="Help Instructions"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- <section>
    <div class="container">
      <h3>High Quality</h3>
      <p>
        Fibn cards are made from professional casino paper card stock which has
        a linen finish with air pokets making them more durable, better handling
        and glide.
      </p>
      <div class="row align-items-start">
        <div class="col-sm-4">
          <figure class="figure">
            <img
              src="../../assets/more-info/fibn-deck.webp"
              class="figure-img img-fluid rounded"
              alt="Fibn Deck"
            />
            <figcaption class="figure-caption text-center">
              Actual Deck
            </figcaption>
          </figure>
        </div>
        <div class="col-sm-4">
          <figure class="figure">
            <img
              src="../../assets/more-info/fibn-card.webp"
              class="figure-img img-fluid rounded"
              alt="Fibn Card"
            />
            <figcaption class="figure-caption text-center">
              Actual Card
            </figcaption>
          </figure>
        </div>
        <div class="col-sm-4">
          <figure class="figure">
            <img
              src="../../assets/more-info/fibn-box.webp"
              class="figure-img img-fluid rounded"
              alt="Fibn Box"
            />
            <figcaption class="figure-caption text-center">
              Actual Box
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section> -->
</template>
